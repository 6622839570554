<template>
  <div class="pa-4">
    <p class="font-weight-bold text-center body-2 text-uppercase">
      {{ title }}
    </p>
    <Bar
      :chart-options="chartOptions"
      :chart-data="dataChart"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>
<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default {
  name: "BarChartDashboard",
  components: { Bar },
  props: {
    title: { type: String },
    chartId: { type: String, default: "bar-chart" },
    datasetIdKey: { type: String, default: "label" },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 300 },
    cssClasses: { default: "", type: String },
    // styles: { type: Object, default: () => {} },
    plugins: { type: Array, default: () => [] },
    dataChart: { type: Object, default: () => {} },
    scaleY: {
      type: Number,
      default: 200,
    },
  },

  created() {
    this.chartOptions.scales.y.suggestedMax = this.scaleY;
  },
  data() {
    return {
      styles: {
        mixHeight: "500px",
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
          },
          y: {
            stacked: true,
            display: true,
            title: {
              display: false,
            },
            suggestedMin: 0,
            suggestedMax: 200,
          },
        },
      },
    };
  },
};
</script>
