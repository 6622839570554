<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">{{ title }}</th>
          <th class="text-left">Quantidades</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="d-flex align-center">
            <span>Media de solicitações recebidas/mês</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Média mensal solicitações recebidas no período de
                apuração</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.mediaDiasResposta }}</td>
        </tr>
        <tr>
          <td class="d-flex align-center">
            <span>Quantidade de solicitações Abertas</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Total de solicitações que ainda estão com status “Aberto” no
                período de apuração</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.totalSolicitacoesAbertas }}</td>
        </tr>
        <tr>
          <td class="d-flex align-center">
            <span>Quantidade de solicitações em andamento</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Total de solicitações que ainda estão com status “Em andamento”
                no período de apuração</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.totalSolicitacoesAndamento }}</td>
        </tr>
        <tr>
          <td class="d-flex align-center">
            <span>Quantidade de solicitações Finalizadas</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Total de solicitações com status “Finalizado” no período de
                apuração</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.totalSolicitacoesFinalizado }}</td>
        </tr>
        <tr>
          <td class="d-flex align-center">
            <span><strong>Total de solicitações recebidas</strong></span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Todas as solicitações recebidas no período de apuração</span
              >
            </v-tooltip>
          </td>
          <td>
            <strong>{{ data?.totalSolicitacoesRecebidas }}</strong>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TabelaDashboardSolicitacoes",
  props: {
    data: {
      type: Object,
    },
    title: { type: String },
  },
};
</script>

<style scoped></style>
