<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">{{ title }}</th>
          <th class="text-left">Média</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="d-flex align-center">
            <span>Media de dias aguardando resposta do representante </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Média de dias que a solicitação ficou parada no status
                “Aguardando Resposta” no período de apuração</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.mediaDiasAguardandoRespostaDoRepresentante }}</td>
        </tr>
        <tr>
          <td class="d-flex align-center">
            <span>Media de dias de atendimento sem aprovação</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Média de dias de atendimento das solicitações da criação até a
                finalização no período de apuração</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.mediaDiasDeAtendimentoSemAprovacao }}</td>
        </tr>
        <tr>
          <td class="d-flex align-center">
            <span>Media de dias de atendimento com aprovação</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Média de dias de atendimento das solicitações da criação até a
                finalização no período de apuração</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.mediaDiasDeAtendimentoComAprovacao }}</td>
        </tr>
        <tr>
          <td class="d-flex align-center">
            <span>Media de dias em aprovação</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Média de dias que a solicitação ficou parada no status “Em
                Aprovação” no período de apuração</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.mediaDiasEmAprovacao }}</td>
        </tr>
        <tr>
          <td class="d-flex align-center">
            <span>Media de dias resposta</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <span
                >Média de dias de atendimento das solicitações do inicio do
                atendimento pelo colaborador ate a finalização</span
              >
            </v-tooltip>
          </td>
          <td>{{ data?.mediaDiasResposta }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TabelaDashboardAtendimentos",
  props: {
    data: {
      type: Object,
    },
    title: { type: String },
  },
};
</script>

<style scoped></style>
