<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn small depressed color="fmq_gray" dark outlined to="/">
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <v-card elevation="1" class="pt-8 mt-4 mb-4 pa-4">
          <v-row>
            <v-col cols="2">
              <v-menu
                ref="menuDataInicio"
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.dataInicio"
                    placeholder="Data Inicial"
                    outlined
                    dense
                    :clearable="true"
                    label="Data Inicial"
                    required
                    :rules="rules.genericRules"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.dataInicio"
                  no-title
                  scrollable
                  locale="pt-br"
                  :max="formData.dataTermino"
                  color="black"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuDataInicio = false">
                    Fechar
                  </v-btn>
                  <v-btn text color="primary" @click="menuDataInicio = false">
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menuDataInicio"
                v-model="menuDataTermino"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.dataTermino"
                    placeholder="Data Final"
                    outlined
                    dense
                    :clearable="true"
                    label="Data Final"
                    required
                    :rules="rules.genericRules"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.dataTermino"
                  no-title
                  scrollable
                  locale="pt-br"
                  :max="nowDate"
                  :min="formData.dataInicio"
                  color="black"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuDataTermino = false">
                    Fechar
                  </v-btn>
                  <v-btn text color="primary" @click="menuDataTermino = false">
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menuComparacaoDataInicio"
                v-model="menuComparacaoDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.comparacaoDataInicio"
                    placeholder="Data de Comparação Inicial"
                    outlined
                    dense
                    :clearable="true"
                    label="Data de Comparação Inicial"
                    required
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.comparacaoDataInicio"
                  no-title
                  scrollable
                  locale="pt-br"
                  color="black"
                  :max="nowDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuComparacaoDataInicio = false"
                  >
                    Fechar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="menuComparacaoDataInicio = false"
                  >
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menuComparacaoDataTermino"
                v-model="menuComparacaoDataTermino"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.comparacaoDataTermino"
                    placeholder="Data de Comparação Final"
                    outlined
                    dense
                    :clearable="true"
                    label="Data de Comparação Final"
                    required
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.comparacaoDataTermino"
                  no-title
                  scrollable
                  locale="pt-br"
                  color="black"
                  :min="formData.comparacaoDataInicio"
                  :max="nowDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuComparacaoDataTermino = false"
                  >
                    Fechar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="menuComparacaoDataTermino = false"
                  >
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-btn
                depressed
                color="green accent-4"
                dark
                :loading="loadingBtn"
                block
                @click="getDashboardDownload()"
                >EXPORTAR XLS</v-btn
              ></v-col
            >
            <v-col cols="2">
              <v-btn
                depressed
                color="fmq_gray"
                dark
                :disabled="loadingBtn"
                :loading="loading"
                block
                @click="getDashboard()"
                >Buscar</v-btn
              ></v-col
            >
          </v-row></v-card
        >

        <v-expansion-panels multiple class="mb-4" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>Relatório</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-progress-circular
                v-if="loading"
                :size="100"
                :width="4"
                color="fmq_black"
                indeterminate
                class="d-flex mx-auto py-8 mt-8"
              ></v-progress-circular>
              <v-row justify="center" class="mt-4 mb-4" v-else>
                <v-col
                  :cols="comparativo ? '5' : ''"
                  v-if="dashboard"
                  class="grey lighten-5 rounded pa-4 mx-2"
                >
                  <p
                    class="font-weight-bold text-center body-1 text-uppercase mb-4"
                  >
                    {{ setDate(dashboard.periodoInicio) }} a
                    {{ setDate(dashboard.periodoTermino) }} ({{
                      dashboard.periodoMeses
                    }}
                    {{ dashboard.periodoMeses > 1 ? "meses" : "mês" }})
                  </p>
                  <TabelaDashboardSolicitacoes
                    :data="dashboard"
                    title="Solicitações"
                    class="mb-8"
                  />
                  <TabelaDashboardAtendimentos
                    :data="dashboard"
                    title="Atendimentos"
                    class="mb-8"
                  />
                  <PieChartDashboard
                    class="mb-8"
                    title="Percentual por linhas (Produtos FQM)"
                    :dataChart="setDataChartLinhas(dashboard.rankingLinhas)"
                  />
                  <BarChartDashboard
                    title="Top 10 dos produtos mais solicitados"
                    :dataChart="setDataChartProdutos(dashboard.rankingProdutos)"
                    :scaleY="dashboard.escalaRankingProdutos"
                  />
                  <LineChartDashboard
                    title="Evolução mensal"
                    :dataChart="setDataChartEvolucao(dashboard.evolucaoMensal)"
                    :scaleY="dashboard.escalaEvolucaoMensal"
                  />
                </v-col>
                <v-col
                  cols="5"
                  v-if="comparativo"
                  class="grey lighten-5 rounded pa-4 mx-2"
                >
                  <p
                    class="font-weight-bold text-center body-1 text-uppercase mb-4"
                  >
                    {{ setDate(comparativo.periodoInicio) }} a
                    {{ setDate(comparativo.periodoTermino) }} ({{
                      comparativo.periodoMeses
                    }}
                    {{ comparativo.periodoMeses > 1 ? "meses" : "mês" }})
                  </p>
                  <TabelaDashboardSolicitacoes
                    :data="comparativo"
                    title="Comparativo de solicitações"
                    class="mb-8"
                  />
                  <TabelaDashboardAtendimentos
                    :data="comparativo"
                    title="Comparativo de atendimentos"
                    class="mb-8"
                  />
                  <PieChartDashboard
                    class="mb-8"
                    title="Comparativo de percentual por linhas (Produtos FQM)"
                    :dataChart="setDataChartLinhas(comparativo.rankingLinhas)"
                  />
                  <BarChartDashboard
                    title="Comparativo do top 10 dos produtos mais solicitados"
                    :dataChart="
                      setDataChartProdutos(comparativo.rankingProdutos)
                    "
                    :scaleY="comparativo.escalaRankingProdutos"
                  />
                  <LineChartDashboard
                    title="Evolução mensal comparativo"
                    :dataChart="
                      setDataChartEvolucao(comparativo.evolucaoMensal)
                    "
                    :scaleY="comparativo.escalaEvolucaoMensal"
                  /> </v-col
              ></v-row>
              <ErrorMessage v-if="showError" message="Dados não encontrados"
            /></v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { stringToHex } from "@/utils";
import BarChartDashboard from "@/components/relatorios/BarChartDashboard.vue";
import PieChartDashboard from "@/components/relatorios/PieChartDashboard.vue";
import TabelaDashboardSolicitacoes from "@/components/relatorios/TabelaDashboardSolicitacoes.vue";
import TabelaDashboardAtendimentos from "@/components/relatorios/TabelaDashboardAtendimentos.vue";
import LineChartDashboard from "@/components/relatorios/LineChartDashboard.vue";
import { dashboardJson, dashboardExel } from "@/services/relatorio";
import { rules } from "@/utils/rules.js";
export default {
  name: "RelatorioDashboard",
  components: {
    TabelaDashboardSolicitacoes,
    PieChartDashboard,
    BarChartDashboard,
    TabelaDashboardAtendimentos,
    LineChartDashboard,
  },
  data: () => ({
    rules: rules,
    breadcrumbs: [
      {
        text: "Relatórios",
        disabled: false,
        to: "",
      },
      {
        text: "Dashboard",
        disabled: true,
        to: "",
      },
    ],
    nowDate: getNowDate(),
    firstDateOfYear: getFirstDateOfYear(),
    panel: [0],
    formData: {
      dataInicio: "",
      dataTermino: "",
      comparacaoDataInicio: "",
      comparacaoDataTermino: "",
    },
    menuDataInicio: false,
    menuDataTermino: false,
    menuComparacaoDataInicio: false,
    menuComparacaoDataTermino: false,
    loadingBtn: false,
    dashboard: null,
    comparativo: null,
    loading: false,
    showError: false,
  }),
  created() {
    this.formData.dataInicio = this.firstDateOfYear;
    this.formData.dataTermino = this.nowDate;
    this.getDashboard();
  },
  methods: {
    async getDashboard() {
      this.loading = true;
      this.showError = false;
      this.dashboard = null;
      this.comparativo = null;
      try {
        const resp = await dashboardJson(this.formData);
        this.dashboard = resp.data.dashboard;
        this.comparativo = resp.data.comparativo;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.showError = true;
        console.error(e);
      }
    },
    async getDashboardDownload() {
      this.loadingBtn = true;
      try {
        const resp = await dashboardExel(this.formData);
        window.open(resp.data.url, "_blank");
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        console.error(e);
      }
    },
    setDataChartLinhas(data) {
      return {
        labels: data.map((item) => item.linhaNome),
        datasets: [
          {
            backgroundColor: data.map((item) => stringToHex(item.linhaNome)),
            data: data.map((item) => item.percentual),
          },
        ],
      };
    },
    setDataChartProdutos(data) {
      return {
        labels: data.map((item) => item.produtoNome),
        datasets: [
          {
            backgroundColor: data.map((item) => stringToHex(item.produtoNome)),
            data: data.map((item) => item.total),
          },
        ],
      };
    },
    setDataChartEvolucao(data) {
      const lineData = {
        labels: [],
        datasets: [
          {
            backgroundColor: "#f87979",
            borderColor: "#f87979",
            data: [],
          },
        ],
      };
      for (const mes in data) {
        lineData.labels.push(mes);
        lineData.datasets[0].data.push(data[mes]);
      }
      return lineData;
    },
    setDate(date) {
      const newDate = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      return newDate;
    },
  },
};

function getFirstDateOfYear() {
  const now = new Date();
  const firstDateOfYear = new Date(now.getFullYear(), 0, 1);
  return firstDateOfYear.toISOString().slice(0, 10);
}

function getNowDate() {
  const now = new Date();
  const saoPauloOffset = -3 * 60;
  const localOffset = now.getTimezoneOffset();
  const offsetDifference = saoPauloOffset - localOffset;
  const saoPauloTime = new Date(now.getTime() + offsetDifference * 60 * 1000);
  const year = saoPauloTime.getFullYear();
  const month = String(saoPauloTime.getMonth() + 1).padStart(2, "0");
  const day = String(saoPauloTime.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
</script>

<style></style>
